define("discourse/plugins/discourse-subscriptions/discourse/helpers/user-viewing-self", ["exports", "discourse/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = userViewingSelf;
  function userViewingSelf(model) {
    if (_user.default.current()) {
      return _user.default.current().username.toLowerCase() === model.username.toLowerCase();
    }
    return false;
  }
});