define("discourse/plugins/discourse-subscriptions/discourse/models/transaction", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    finalize(transaction, plan) {
      const data = {
        transaction,
        plan
      };
      return (0, _ajax.ajax)("/s/finalize", {
        method: "post",
        data
      });
    }
  };
});