define("discourse/plugins/discourse-subscriptions/discourse/helpers/format-unix-date", ["exports", "@ember/template", "discourse/lib/formatter"], function (_exports, _template, _formatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatUnixDate;
  function formatUnixDate(timestamp) {
    if (timestamp) {
      const date = new Date(moment.unix(timestamp).format());
      return new _template.htmlSafe((0, _formatter.autoUpdatingRelativeAge)(date, {
        format: "medium",
        title: true,
        leaveAgo: true
      }));
    }
  }
});