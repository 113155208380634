define("discourse/plugins/discourse-subscriptions/discourse/templates/connectors/above-main-container/subscriptions-campaign", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CampaignBanner @connectorName="above-main-container" />
  */
  {
    "id": "jx9jcrMV",
    "block": "[[[8,[39,0],null,[[\"@connectorName\"],[\"above-main-container\"]],null]],[],false,[\"campaign-banner\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/connectors/above-main-container/subscriptions-campaign.hbs",
    "isStrictMode": false
  });
});