define("discourse/plugins/discourse-subscriptions/discourse/templates/connectors/before-topic-list/subscriptions-campaign-sidebar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CampaignBanner @connectorName="before-topic-list" />
  */
  {
    "id": "FTy1fqip",
    "block": "[[[8,[39,0],null,[[\"@connectorName\"],[\"before-topic-list\"]],null]],[],false,[\"campaign-banner\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/connectors/before-topic-list/subscriptions-campaign-sidebar.hbs",
    "isStrictMode": false
  });
});