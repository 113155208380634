define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-dashboard", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    queryParams: ["order", "descending"],
    order: null,
    descending: true,
    actions: {
      loadMore() {},
      orderPayments(order) {
        if (order === this.get("order")) {
          this.toggleProperty("descending");
        }
        this.set("order", order);
      }
    }
  });
});