define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions-subscriptions", ["exports", "@ember/routing/route", "discourse/plugins/discourse-subscriptions/discourse/models/admin-subscription"], function (_exports, _route, _adminSubscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    model() {
      return _adminSubscription.default.find();
    }
  });
});