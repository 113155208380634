define("discourse/plugins/discourse-subscriptions/discourse/components/modal/admin-cancel-subscription", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/helper", "discourse/components/d-button", "discourse/components/d-modal", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _helper, _dButton, _dModal, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminCancelSubscription extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "refund", [_tracking.tracked]))();
    #refund = (() => (dt7948.i(this, "refund"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @title={{i18n
            "discourse_subscriptions.user.subscriptions.operations.destroy.confirm"
          }}
          @closeModal={{@closeModal}}
        >
          <:body>
            <Input @type="checkbox" @checked={{this.refund}} />
            {{i18n "discourse_subscriptions.admin.ask_refund"}}
          </:body>
          <:footer>
            <DButton
              @label="yes_value"
              @action={{fn
                @model.cancelSubscription
                (hash
                  subscription=@model.subscription
                  refund=this.refund
                  closeModal=@closeModal
                )
              }}
              @icon="times"
              @isLoading={{@model.subscription.loading}}
              class="btn-danger"
            />
            <DButton @label="no_value" @action={{@closeModal}} />
          </:footer>
        </DModal>
      
    */
    {
      "id": "ZuO816X/",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_subscriptions.user.subscriptions.operations.destroy.confirm\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[8,[32,2],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"refund\"]]]],null],[1,\"\\n        \"],[1,[28,[32,1],[\"discourse_subscriptions.admin.ask_refund\"],null]],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,0,\"btn-danger\"]],[[\"@label\",\"@action\",\"@icon\",\"@isLoading\"],[\"yes_value\",[28,[32,4],[[30,2,[\"cancelSubscription\"]],[28,[32,5],null,[[\"subscription\",\"refund\",\"closeModal\"],[[30,2,[\"subscription\"]],[30,0,[\"refund\"]],[30,1]]]]],null],\"times\",[30,2,[\"subscription\",\"loading\"]]]],null],[1,\"\\n        \"],[8,[32,3],null,[[\"@label\",\"@action\"],[\"no_value\",[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-subscriptions/discourse/components/modal/admin-cancel-subscription.js",
      "scope": () => [_dModal.default, _i18n.default, _component2.Input, _dButton.default, _helper.fn, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdminCancelSubscription;
});