define("discourse/plugins/discourse-subscriptions/discourse/routes/user-billing-subscriptions-index", ["exports", "@ember/object", "@ember/routing/route", "@ember/service", "I18n", "discourse/plugins/discourse-subscriptions/discourse/models/user-subscription"], function (_exports, _object, _route, _service, _I18n, _userSubscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(dt7948.p({
    dialog: (0, _service.inject)(),
    router: (0, _service.inject)(),
    model() {
      return _userSubscription.default.findAll();
    },
    updateCard(subscriptionId) {
      this.router.transitionTo("user.billing.subscriptions.card", subscriptionId);
    },
    cancelSubscription(subscription) {
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("discourse_subscriptions.user.subscriptions.operations.destroy.confirm"),
        didConfirm: () => {
          subscription.set("loading", true);
          subscription.destroy().then(result => subscription.set("status", result.status)).catch(data => this.dialog.alert(data.jqXHR.responseJSON.errors.join("\n"))).finally(() => {
            subscription.set("loading", false);
            this.refresh();
          });
        }
      });
    }
  }, [["method", "updateCard", [_object.action]], ["method", "cancelSubscription", [_object.action]]]));
});