define("discourse/plugins/discourse-subscriptions/discourse/connectors/after-topic-footer-buttons/subscriptions-campaign-topic-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      const {
        siteSettings
      } = component;
      const bannerLocation = siteSettings.discourse_subscriptions_campaign_banner_location;
      return bannerLocation === "Top" || bannerLocation === "Sidebar";
    }
  };
});