define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-plans-show", ["exports", "@ember/controller", "@ember/service", "discourse/lib/ajax-error"], function (_exports, _controller, _service, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    router: (0, _service.inject)(),
    actions: {
      createPlan() {
        if (this.get("model.plan.product_id") === undefined) {
          const productID = this.get("model.products.firstObject.id");
          this.set("model.plan.product_id", productID);
        }
        this.get("model.plan").save().then(() => {
          this.router.transitionTo("adminPlugins.discourse-subscriptions.plans");
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });
});