define("discourse/plugins/discourse-subscriptions/discourse/subscriptions-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("subscriptions", {
      path: "/s/subscriptions"
    });
    this.route("subscribe", {
      path: "/s"
    }, function () {
      this.route("show", {
        path: "/:subscription-id"
      });
    });
  }
});