define("discourse/plugins/discourse-subscriptions/discourse/templates/connectors/after-topic-footer-buttons/subscriptions-campaign-topic-footer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CampaignBanner @connectorName="after-topic-footer-buttons" />
  */
  {
    "id": "0GgjL+jf",
    "block": "[[[8,[39,0],null,[[\"@connectorName\"],[\"after-topic-footer-buttons\"]],null]],[],false,[\"campaign-banner\"]]",
    "moduleName": "discourse/plugins/discourse-subscriptions/discourse/templates/connectors/after-topic-footer-buttons/subscriptions-campaign-topic-footer.hbs",
    "isStrictMode": false
  });
});