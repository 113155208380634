define("discourse/plugins/discourse-subscriptions/discourse/components/subscribe-ca-province-select", ["exports", "@ember/object", "I18n", "select-kit/components/combo-box"], function (_exports, _object, _I18n, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend({
    pluginApiIdentifiers: ["subscribe-ca-province-select"],
    classNames: ["subscribe-address-state-select"],
    nameProperty: "name",
    valueProperty: "value",
    selectKitOptions: {
      filterable: true,
      allowAny: false,
      translatedNone: _I18n.default.t("discourse_subscriptions.subscribe.cardholder_address.province")
    },
    content: (0, _object.computed)(function () {
      return [["AB", "Alberta"], ["BC", "British Columbia"], ["MB", "Manitoba"], ["NB", "New Brunswick"], ["NL", "Newfoundland and Labrador"], ["NT", "Northwest Territories"], ["NS", "Nova Scotia"], ["NU", "Nunavut"], ["ON", "Ontario"], ["PE", "Prince Edward Island"], ["QC", "Quebec"], ["SK", "Saskatchewan"], ["YT", "Yukon"]].map(arr => {
        return {
          value: arr[0],
          name: arr[1]
        };
      });
    })
  });
});