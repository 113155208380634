define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-products-show-plans-show", ["exports", "@ember/controller", "@ember/object/computed", "discourse/lib/ajax-error", "discourse/lib/url", "discourse-common/utils/decorators"], function (_exports, _controller, _computed, _ajaxError, _url, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RECURRING = "recurring";
  const ONE_TIME = "one_time";
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    // Also defined in settings.
    selectedCurrency: (0, _computed.alias)("model.plan.currency"),
    selectedInterval: (0, _computed.alias)("model.plan.interval"),
    selectedGroup(groupName) {
      return groupName || "no-group";
    },
    availableGroups(groups) {
      return [{
        id: null,
        name: "no-group"
      }, ...groups];
    },
    currencies() {
      return [{
        id: "AUD",
        name: "AUD"
      }, {
        id: "CAD",
        name: "CAD"
      }, {
        id: "EUR",
        name: "EUR"
      }, {
        id: "GBP",
        name: "GBP"
      }, {
        id: "USD",
        name: "USD"
      }, {
        id: "INR",
        name: "INR"
      }, {
        id: "BRL",
        name: "BRL"
      }, {
        id: "DKK",
        name: "DKK"
      }, {
        id: "SGD",
        name: "SGD"
      }, {
        id: "JPY",
        name: "JPY"
      }, {
        id: "ZAR",
        name: "ZAR"
      }];
    },
    availableIntervals() {
      return [{
        id: "day",
        name: "day"
      }, {
        id: "week",
        name: "week"
      }, {
        id: "month",
        name: "month"
      }, {
        id: "year",
        name: "year"
      }];
    },
    planFieldDisabled(isNew) {
      return !isNew;
    },
    productId(id) {
      return id;
    },
    redirect(product_id) {
      _url.default.redirectTo(`/admin/plugins/discourse-subscriptions/products/${product_id}`);
    },
    actions: {
      changeRecurring() {
        const recurring = this.get("model.plan.isRecurring");
        this.set("model.plan.type", recurring ? ONE_TIME : RECURRING);
        this.set("model.plan.isRecurring", !recurring);
      },
      createPlan() {
        if (this.model.plan.metadata.group_name === "no-group") {
          this.set("model.plan.metadata.group_name", null);
        }
        this.get("model.plan").save().then(() => this.redirect(this.productId)).catch(_ajaxError.popupAjaxError);
      },
      updatePlan() {
        if (this.model.plan.metadata.group_name === "no-group") {
          this.set("model.plan.metadata.group_name", null);
        }
        this.get("model.plan").update().then(() => this.redirect(this.productId)).catch(_ajaxError.popupAjaxError);
      }
    }
  }, [["method", "selectedGroup", [(0, _decorators.default)("model.plan.metadata.group_name")]], ["method", "availableGroups", [(0, _decorators.default)("model.groups")]], ["method", "currencies", [_decorators.default]], ["method", "availableIntervals", [_decorators.default]], ["method", "planFieldDisabled", [(0, _decorators.default)("model.plan.isNew")]], ["method", "productId", [(0, _decorators.default)("model.product.id")]]]));
});