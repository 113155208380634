define("discourse/plugins/discourse-subscriptions/discourse/helpers/stripe-payment-link", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.helper(function (params) {
    const payment = params[0];
    return `<a href=\"${payment.url}\">${payment.payment_intent_id}</a>`;
  });
});