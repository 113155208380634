define("discourse/plugins/discourse-subscriptions/discourse/controllers/subscribe-index", ["exports", "@ember/controller", "discourse/models/user", "discourse-common/utils/decorators"], function (_exports, _controller, _user, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    isLoggedIn() {
      return _user.default.current();
    }
  }, [["method", "isLoggedIn", [(0, _decorators.default)()]]]));
});