define("discourse/plugins/discourse-subscriptions/discourse/controllers/admin-plugins-discourse-subscriptions-plans-index", ["exports", "@ember/controller", "discourse/lib/url"], function (_exports, _controller, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    actions: {
      editPlan(id) {
        return _url.default.redirectTo(`/admin/plugins/discourse-subscriptions/plans/${id}`);
      }
    }
  });
});