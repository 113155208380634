define("discourse/plugins/discourse-subscriptions/discourse/controllers/user-billing-subscriptions-card", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n"], function (_exports, _controller, _object, _service, _ajax, _ajaxError, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global Stripe */
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    dialog: (0, _service.inject)(),
    loading: false,
    saved: false,
    init() {
      this._super(...arguments);
      this.set("stripe", Stripe(this.siteSettings.discourse_subscriptions_public_key));
      const elements = this.get("stripe").elements();
      this.set("cardElement", elements.create("card", {
        hidePostalCode: true
      }));
    },
    async updatePaymentMethod() {
      this.set("loading", true);
      this.set("saved", false);
      const paymentMethodObject = await this.stripe.createPaymentMethod({
        type: "card",
        card: this.cardElement
      });
      if (paymentMethodObject.error) {
        this.dialog.alert(paymentMethodObject.error?.message || _I18n.default.t("generic_error"));
        this.set("loading", false);
        return;
      }
      const paymentMethod = paymentMethodObject.paymentMethod.id;
      try {
        await (0, _ajax.ajax)(`/s/user/subscriptions/${this.model}`, {
          method: "PUT",
          data: {
            payment_method: paymentMethod
          }
        });
        this.set("saved", true);
      } catch (err) {
        (0, _ajaxError.popupAjaxError)(err);
      } finally {
        this.set("loading", false);
        this.cardElement?.clear();
      }
    }
  }, [["method", "updatePaymentMethod", [_object.action]]]));
});