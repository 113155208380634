define("discourse/plugins/discourse-subscriptions/discourse/discourse-subscriptions-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin.adminPlugins",
    path: "/plugins",
    map() {
      this.route("discourse-subscriptions", function () {
        this.route("products", function () {
          this.route("show", {
            path: "/:product-id"
          }, function () {
            this.route("plans", function () {
              this.route("show", {
                path: "/:plan-id"
              });
            });
          });
        });
        this.route("coupons");
        this.route("subscriptions");
      });
    }
  };
});