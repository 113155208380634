define("discourse/plugins/discourse-subscriptions/discourse/routes/admin-plugins-discourse-subscriptions-coupons", ["exports", "@ember/object", "@ember/routing/route", "discourse/plugins/discourse-subscriptions/discourse/models/admin-coupon"], function (_exports, _object, _route, _adminCoupon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(dt7948.p({
    model() {
      return _adminCoupon.default.list();
    },
    reloadModel() {
      this.refresh();
    }
  }, [["method", "reloadModel", [_object.action]]]));
});